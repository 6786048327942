import React from "react"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import Legals from "components/molecules/Legals"

const PRIVACY_TOPICS = [
  "executiveDevelopment",
  "whoWeAre",
  "informationWeCollect",
  "howWeUseInformation",
  "informationWeShare",
  "recordKeeping",
  "yourChoices",
  "linksToOtherWebsites",
  "howWeProtectPersonalInfo",
  "updatesToPrivacyNotice",
  "howToContact",
  "noticeToCaliforniaResidents",
]
const PrivacyPage = ({ pageContext: { language } }) => (
  <Layout language={language}>
    <SEO title="Privacy Policy" />
    <Legals topics={PRIVACY_TOPICS} pageType="privacy" />
  </Layout>
)

PrivacyPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default PrivacyPage
